import React, { useRef, useState } from 'react';
import { FaSort } from 'react-icons/fa';
import moment from 'moment';

import { capitalizeFirstLetter } from '../../../services/utils';
import StickyHeader from '../../../shared/components/StickyHeader';
import './CompanyUsersDetails.scss';

function CompanyUsersDetails({ data = [], branches = [], roles = [] }) {
  const usersListRef = useRef(null);
  const [sortKey, setSortKey] = useState(null);
  const [orderDirectionAsc, setOrderDirectionAsc] = useState(true);
  // Calculating Users
  data.forEach((user) => {
    const branch = branches.find((a) => a.id === user.branchId);
    const role = roles.find((a) => a.id === user.roleId);
    user.branch = branch?.name;
    user.role = role?.name;
  });

  const toggleSort = (key) => {
    if (key === sortKey) {
      setOrderDirectionAsc(!orderDirectionAsc);
    } else {
      setSortKey(key);
      setOrderDirectionAsc(true);
    }
  };
  if (sortKey !== null && data.length > 0) {
    data.sort((a, b) => {
      if (sortKey === 'id') return Number(a[sortKey]) - Number(b[sortKey]);
      if (sortKey === 'users') return a.users.length - b.users.length;
      if (sortKey === 'sub-branches') return a.subBranches.length - b.subBranches.length;
      if (sortKey === 'createdAt') return moment(a[sortKey]).unix() - moment(b[sortKey]).unix();
      const aValue = String(a[sortKey]);
      const bValue = String(b[sortKey]);
      return aValue.localeCompare(bValue);
    });
    if (!orderDirectionAsc) data.reverse();
  }

  const branchTile = (item = {}) => {
    return (
      <div className={'item'}>
        <p className="single name">{capitalizeFirstLetter(item.firstName)}</p>
        <p className="single last-login">
          {item.createdAt ? moment(item.createdAt).format('DD-MMM-YYYY') : 'N/A'}
        </p>
        <p className="single average-activity-time">{item.branch ? item.branch ?? 'N/A' : 'N/A'}</p>
        <p className="single most-visited-pages">{item.role ? item.role ?? 'N/A' : 'N/A'}</p>
        {item.status === 'ACTIVE' && <p className="single status">Active</p>}
        {item.status !== 'ACTIVE' && <p className="single status">Inactive</p>}
      </div>
    );
  };
  return (
    <div className={'CompanyUsersDetails'}>
      <div className="header">Users ({data.length})</div>
      <StickyHeader bodyRef={usersListRef} topPositionHeader={0} thresholdSticky={0}>
        <div className="key-labels">
          <p className="key name" onClick={() => toggleSort('firstName')}>
            Name <FaSort />
          </p>
          <p className="key last-login" onClick={() => toggleSort('createdAt')}>
            Created At <FaSort />
          </p>
          <p className="key average-activity-time" onClick={() => toggleSort('branch')}>
            Branch <FaSort />
          </p>
          <p className="key most-visited-pages" onClick={() => toggleSort('role')}>
            Role <FaSort />
          </p>
          <p className="key status" onClick={() => toggleSort('status')}>
            Status <FaSort />
          </p>
        </div>
      </StickyHeader>
      <div className="items-container" ref={usersListRef}>
        {data.map(branchTile)}
      </div>
    </div>
  );
}

export default CompanyUsersDetails;
