import * as passwordValidator from 'password-validator';

export const validatePassword = (password) => {
  const schema = new passwordValidator();
  schema
    .is().min(8, 'Password must be at least 8 characters')
    .is().max(20, 'Password must no be more than 20 characters')
    .has().uppercase(1, 'Password must contain at least one uppercase letter')
    .has().lowercase(1, 'Password must contain at least one lowercase letter')
    .has().symbols(1,'Password must contain at least one symbol')
    .has().digits(1, 'Password must contain at least one number')
    .has().not().spaces(1, 'Password must not contain any spaces')
  return schema.validate(password, { details: true });
};