import {STORAGE_KEY} from "./constants";

export const getAccessToken = () => window.sessionStorage.getItem(STORAGE_KEY.TOKEN);
export const clearAccessToken = () => window.sessionStorage.setItem(STORAGE_KEY.TOKEN, "");

export const getCurrentUser = () => {
    try {
        let user = window.sessionStorage.getItem(STORAGE_KEY.USER);
        return JSON.parse(user);
    }catch (e){
        return null;
    }
};
export const clearCurrentUser = () => window.sessionStorage.setItem(STORAGE_KEY.USER, "");