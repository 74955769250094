import axios from "axios";
import endpoints from "./endpoints";
import {clearAccessToken, clearCurrentUser, getAccessToken} from "./token.utils";

export const logoutRequest = async () => {
    try {
        let token = getAccessToken();
        if (token) {
            await axios.post(endpoints.logoutAPI(), {
                deviceType: "web",
                deviceToken: ""
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        window.location.pathname = "/login";
    } catch (e) {
        if (e.response.status === 401) {
            window.location.pathname = "/login";
            clearAccessToken();
            clearCurrentUser();
        }
    } finally {
        clearAccessToken();
        clearCurrentUser();
    }

}