import React, { useEffect, useState } from 'react';
import "./UserLayout.scss";
import { Col, Container, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import Header from "../../Header";
import Sidebar from "../../Sidebar";

function UserLayout({ children }) {
    const [isLayoutAllowed, setIsLayoutAllowed] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes("/login")) {
            setIsLayoutAllowed(false);
        } else {
            setIsLayoutAllowed(true);
        }
    }, [location]);

    if (!isLayoutAllowed) return <>{children}</>
    return (
        <div className={"UserLayout"}>
            {isLayoutAllowed && <Row>
                <Col md={12}><Header /></Col>
            </Row>}
            <Container>
                <Row>
                    <div className={"side-bar-container"}><Sidebar /></div>
                    <div className={`content-container`}>{children}</div>
                </Row>
            </Container>
        </div>
    );
}

export default UserLayout;
