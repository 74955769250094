import React, {useState} from 'react';
import Logo from "../../assets/logo.png"
import "./Header.scss"
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {FaCaretDown} from "react-icons/fa";
import {logoutRequest} from "../../../services/http.utils";
import {getCurrentUser} from "../../../services/token.utils";
import {useHistory} from "react-router-dom";

const Header = () => {
    const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
    const history = useHistory();
    let user = getCurrentUser();

    return (
        <div className={"Header"}>
            <div className="logo-container cursor-pointer" onClick={() => history.push("/dashboard")}>
                <img src={Logo} alt={"insightt-logo"} className={"logo"}/>
            </div>
            <div className="action-container">
                <Dropdown isOpen={dropDownIsOpen} toggle={() => setDropDownIsOpen(!dropDownIsOpen)}>
                    <DropdownToggle tag="div" type="button" className="header-left d-flex">
                        <div className="avatar">
                            {user?.avatarUrl && <img src={user?.avatarUrl} alt={user?.firstName}/>}
                            {!user?.avatarUrl && <img
                                src="https://thumbs.dreamstime.com/b/default-avatar-profile-vector-user-profile-default-avatar-profile-vector-user-profile-profile-179376714.jpg"
                                alt={user?.firstName}/>}
                        </div>
                        <div className="info">
                            <div className="name">{user?.firstName} {" "}<FaCaretDown/></div>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={logoutRequest}>
                            Logout
                        </DropdownItem>

                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
};

export default Header;
