import React from 'react';
import { FaBuilding, FaChartLine } from 'react-icons/fa';

export const Menu = [
  {
    title: 'Dashboard',
    icon: <FaChartLine />,
    link: '/dashboard',
  },
  {
    title: 'Companies',
    icon: <FaBuilding />,
    link: '/company',
  },
];
