import axios from 'axios';
import {getAccessToken} from "../../../services/token.utils";
import endpoints from '../../../services/endpoints';

export const postEmail = async (data) => {
  try {
      let res = await axios.post(endpoints.customEmailApi(), data, {headers: {Authorization: `Bearer ${getAccessToken()}`}});
      return res.data;
  } catch (e) {
      console.error(e);
      return [];
  }
}