import axios from 'axios';
import endpoints from '../../services/endpoints';
import { STORAGE_KEY } from '../../services/constants';
import { toast } from 'react-toastify';

export const loginRequest = async (email, password) => {
  console.log('loginRequest:', email);
  console.log('loginRequest:', endpoints.loginAPI());
  let res;
  try {
    res = await axios.post(endpoints.loginAPI(), { email, password, deviceType: 'web', deviceToken: '' });
  } catch (e) {
    console.log('loginRequest:error:', e);
    toast.error(e.response.data.message);
    return false;
  }
  console.log('loginRequest:res:', res);
  const { platformUser, token, user } = res.data;

  if (platformUser?.role === 'system_admin') {
    window.sessionStorage.setItem(STORAGE_KEY.TOKEN, token);
    if (user) {
      window.sessionStorage.setItem('rra-sa-user', JSON.stringify(user));
    }
    return true;
  } else {
    toast.error(res.data.message || 'Your user is unauthorized');
    return false;
  }
};
