import React from 'react';
import {Redirect, Route} from "react-router-dom";

import {isAuthenticated} from "./PrivateRoute.helper";
import "./PrivateRoute.scss";

function PrivateRoute({component: Component, ...rest}) {
    return <Route {...rest} render={props => (isAuthenticated() ? <Component {...props} /> : <Redirect to="/login"/>)}/>
}

export default PrivateRoute;