export const QUOTES = [
  {
    quote:
      "Love is patient and kind; love does not envy or boast; it is not arrogant or rude. It does not insist on its own way; it is not irritable or resentful; it does not rejoice at wrongdoing, but rejoices with the truth. Love bears all things, believes all things, hopes all things, endures all things. Love never ends. As for prophecies, they will pass away; as for tongues, they will cease; as for knowledge, it will pass away.",
    author: "Corinthians 13:4-8"
  },
  { quote: "Let all that you do be done in love.", author: "Corinthians 16:14" },
  {
    quote:
      "“For God so loved the world, that he gave his only Son, that whoever believes in him should not perish but have eternal life.",
    author: "John 3:16"
  },
  { quote: "Anyone who does not love does not know God, because God is love.", author: "1 John 4:8" },
  {
    quote: "Above all, keep loving one another earnestly, since love covers a multitude of sins.",
    author: "1 Peter 4:8"
  },
  {
    quote: "And above all these put on love, which binds everything together in perfect harmony.",
    author: "Colossians 3:14"
  },
  {
    quote: "Greater love has no one than this, that someone lay down his life for his friends.",
    author: "John 15:13"
  },
  {
    quote:
      "A new commandment I give to you, that you love one another: just as I have loved you, you also are to love one another. By this all people will know that you are my disciples, if you have love for one another.”",
    author: "John 13:34-35"
  },
  {
    quote: "So now faith, hope, and love abide, these three; but the greatest of these is love.",
    author: "1 Corinthians 13:13"
  },
  { quote: "We love because he first loved us.", author: "1 John 4:19" },
  { quote: "Hatred stirs up strife, but love covers all offenses.", author: "Proverbs 10:12" },
  {
    quote:
      "Jesus answered, “The most important is, ‘Hear, O Israel: The Lord our God, the Lord is one. And you shall love the Lord your God with all your heart and with all your soul and with all your mind and with all your strength.’ The second is this: ‘You shall love your neighbor as yourself.’ There is no other commandment greater than these.”",
    author: "Mark 12:29-31"
  },
  {
    quote:
      "Beloved, let us love one another, for love is from God, and whoever loves has been born of God and knows God.",
    author: "1 John 4:7"
  },
  {
    quote:
      "There is no fear in love, but perfect love casts out fear. For fear has to do with punishment, and whoever fears has not been perfected in love.",
    author: "1 John 4:18"
  },
  {
    quote: "But God shows his love for us in that while we were still sinners, Christ died for us.",
    author: "Romans 5:8"
  },
  {
    quote:
      "If I speak in the tongues of men and of angels, but have not love, I am a noisy gong or a clanging cymbal. And if I have prophetic powers, and understand all mysteries and all knowledge, and if I have all faith, so as to remove mountains, but have not love, I am nothing. If I give away all I have, and if I deliver up my body to be burned, but have not love, I gain nothing. Love is patient and kind; love does not envy or boast; it is not arrogant or rude. It does not insist on its own way; it is not irritable or resentful; ...",
    author: "1 Corinthians 13:1-13"
  }
];

export const getQuote = () => {
  const randomIndex = Math.floor(Math.random() * QUOTES.length);
  return QUOTES[randomIndex];
};
