import React, { useState } from 'react';
import moment from 'moment';
import './company-card.scss';
import CustomBadge from '../../../../shared/components/custom-badge';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import {
  approveCompany,
  checkCompanyStatus,
  deleteCompany,
  rejectCompany,
  suspendCompany,
} from '../../../../shared/helpers/company-helpers';
import { FaEllipsisV } from 'react-icons/fa';
import ResetPasswordModal from '../../../../shared/components/ResetPasswordModal/ResetPasswordModal';
import SendEmailModal from '../../../../shared/components/SendEmailModal';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

function CompanyCard({ data, fetch }) {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [moreDropDown, setMoreDropDown] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState({
    status: false,
    data: {},
  });
  const [sendEmailModal, setSendEmailModal] = useState({
    status: false,
    data: {},
  });
  const history = useHistory();
  const onApprove = async () => {
    let res = await approveCompany(data.id);
    if (res) {
      toast.success('Company approved successfully');
      fetch();
    } else {
      toast.error('Something went wrong');
    }
  };
  const onReject = async () => {
    let res = await rejectCompany(data.id);
    if (res) {
      toast.success('Company rejected successfully');
      fetch();
    } else {
      toast.error('Something went wrong');
    }
  };
  const onSuspend = async () => {
    let res = await suspendCompany(data.id);
    if (res) {
      toast.success('Company suspended successfully');
      fetch();
    } else {
      toast.error('Something went wrong');
    }
  };
  const onDelete = async () => {
    let res = await deleteCompany(data.id);
    if (res) {
      toast.success('Company deleted successfully');
      fetch();
    } else {
      toast.error('Something went wrong');
    }
  };

  // const onDelete = async () => {
  // 	const res = await deleteCompany(data.id)
  // 	if (!res) return toast.error("Something went wrong")
  // 	toast.success("Company Deleted successfully")
  // 	fetch();
  // }

  return (
    <div className={'d-flex gap-1 w-100 CompanyCard'}>
      <div className="w-5 align-self-center">{data.id}</div>
      <div className="w-30 align-self-center">{data.name}</div>
      <div className="w-20 align-self-center">{data.email}</div>
      <div className="w-15 align-self-center">{moment(data.createdAt).format('MM-DD-YYYY')}</div>
      <div className={data.status !== 'pending' ? 'w-20' : 'w-30'}>
        {data.status !== 'pending' && (
          <>
            <Dropdown isOpen={dropDownOpen} toggle={() => setDropDownOpen(!dropDownOpen)}>
              <DropdownToggle tag="div" type="button" className="header-left d-flex">
                <CustomBadge
                  title={checkCompanyStatus(data.status).label}
                  color={checkCompanyStatus(data.status).color}
                  icon={true}
                />
              </DropdownToggle>
              <DropdownMenu>
                {data.status !== 'approved' && <DropdownItem onClick={onApprove}>Activate</DropdownItem>}
                {data.status !== 'suspended' && <DropdownItem onClick={onSuspend}>Suspend</DropdownItem>}
                {data.status !== 'deleted' && <DropdownItem onClick={onDelete}>Delete</DropdownItem>}
              </DropdownMenu>
            </Dropdown>
          </>
        )}
        {data.status === 'pending' && (
          <>
            <Button size="sm" style={{ fontSize: '0.7rem' }} color={'primary'} onClick={onApprove}>
              Approve
            </Button>
            <Button style={{ marginLeft: 5, fontSize: '0.7rem', padding: '0.3rem' }} onClick={onReject} size="sm">
              Reject
            </Button>
          </>
        )}
      </div>
      <div className={`${data.status === 'pending' ? 'w-2' : 'w-10'} d-flex justify-content-end`}>
        <Dropdown isOpen={moreDropDown} toggle={() => setMoreDropDown(!moreDropDown)}>
          <DropdownToggle tag="div" type="button" className="header-left d-flex">
            <Button className={'more-icon'}>
              <FaEllipsisV />
            </Button>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => history.push(`/company/${data.id}`)}>View Company</DropdownItem>
            <DropdownItem onClick={() => setResetPasswordModal({ status: true, data: data })}>
              Password Reset
            </DropdownItem>
            <DropdownItem onClick={() => setSendEmailModal({ status: true, data: data, origin: 'card' })}>
              Send Email
            </DropdownItem>
            {/* supend or delete company */}
            {/* {
							data.status === 'suspended' && (
								<DropdownItem onClick={onDelete}>
									Delete company
								</DropdownItem>
							)
						} */}
          </DropdownMenu>
        </Dropdown>
      </div>
      {resetPasswordModal.status && (
        <ResetPasswordModal
          status={resetPasswordModal.status}
          data={resetPasswordModal.data}
          toggle={() =>
            setResetPasswordModal({
              status: false,
              data: {},
            })
          }
        />
      )}
      {sendEmailModal.status && (
        <SendEmailModal
          status={sendEmailModal.status}
          data={sendEmailModal.data}
          origin={sendEmailModal.origin}
          toggle={() =>
            setSendEmailModal({
              status: false,
              data: {},
            })
          }
        />
      )}
    </div>
  );
}

export default CompanyCard;
