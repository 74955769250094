import React from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import './BackButton.scss';

function BackButton({ onClick, className, ...rest }) {
  const history = useHistory();

  return (
    <div className="BackButton">
      <div
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            history.goBack();
          }
        }}
        className={`link mb-0 f-std-bold ${className}`}
        {...rest}
      >
        <div className="GoBackContainer text-blue" style={{ cursor: 'pointer' }}>
          <FaAngleLeft size={20} />
          <span>Go Back</span>
        </div>
      </div>
    </div>
  );
}

export default BackButton;
