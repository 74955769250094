import React from 'react';
import "./DashboardPage.scss";

function DashboardPage() {
    return (
        <div className={"DashboardPage"}>

        </div>
    );
}

export default DashboardPage;
