import axios from "axios";
import endpoints from "../../services/endpoints";
import {getAccessToken} from "../../services/token.utils";
import {logoutRequest} from "../../services/http.utils";

export const getCompaniesById = async (id) => {
    try {
        let res = await axios.get(endpoints.getCompaniesByIdAPI(id), {headers: {Authorization: `Bearer ${getAccessToken()}`}});
        return res.data;
    } catch (e) {
        console.error(e);
        return { companyDetails: null };
    }
}

export const getRoles = async () => {
    try {
        let res = await axios.get(endpoints.getRolesAPI(), {headers: {Authorization: `Bearer ${getAccessToken()}`}});
        return res.data.roles;
    } catch (e) {
        if (e.response.status === 401) {
            logoutRequest()
        }
        console.error(e);
        return [];
    }
}
