import axios from "axios";
import endpoints from "../../services/endpoints";
import setHeaders from "../utils/set-headers";

export const checkCompanyStatus = (status) => {
    let finalStatus
    switch (status) {
        case 'approved': finalStatus = ({ color: '#13BF94', label: 'Activated', classname: 'isActive'})
        break
        case 'rejected':  finalStatus = ({ color: '#F24949', label: 'Rejected', classname: 'isRejected'})
        break
        case 'deleted':  finalStatus = ({ color: '#F24949', label: 'Deleted', classname: 'isDeleted'})
        break
        case 'suspended':  finalStatus = ({ color: '#22333b', label: 'Suspended', classname: 'isSuspended'})
        break
        default: finalStatus = ({ color: '#fff', label: 'N/A'})
            break;
    }
    return finalStatus
}

export const approveCompany = async id => {
    try {
        let res = await axios.post(endpoints.approveCompanyAPI(id), {}, setHeaders.authorizationWithToken());
        return res.status === 200;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export const rejectCompany = async id => {
    try {
        let res = await axios.post(endpoints.rejectCompanyAPI(id), {}, setHeaders.authorizationWithToken());
        return res.status === 200;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export const deleteCompany = async id => {
    try {
        let res = await axios.post(endpoints.deleteCompanyAPI(id), {}, setHeaders.authorizationWithToken());
        return res.status === 200;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export const suspendCompany = async id => {
    try {
        const res = await axios.post(endpoints.suspendCompanyApi(id), {}, setHeaders.authorizationWithToken())
        return res.status === 200;
    } catch (e) {
        console.error(e);
        return false
    }
}

export const deleteCompanyOld = async id => {
  try {
    const res = await axios.delete(endpoints.deleteCompanyApi(id), {}, setHeaders.authorizationWithToken())
    return res.status === 200;
  } catch (e) {
      console.error(e);
      return false
  }
}