import React from "react";
import {FaCaretDown} from "react-icons/fa";

const CustomBadge = ({title, color = "#006aff", icon = false}) => {
    return (
        <>
      <span
          className="px-2 py-1 mr-2 mb-2 f-std-book text-center text-capitalize font-weight-small"
          style={{
              color: `${color}`,
              backgroundColor: `${color}30`,
              display: "inline-block",
              borderRadius: "5px",
              fontSize: "1rem",
              width: "fit-content"
          }}
      >
        {title} {icon ? <FaCaretDown/> : null}
      </span>
        </>
    );
};

export default CustomBadge;
