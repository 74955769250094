import React, { useEffect, useRef, useState } from 'react';
import './StickyHeader.scss';

export const StickyHeader = (props) => {
  const { bodyRef, children, topPositionHeader = 150, thresholdSticky = 160 } = props;

  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const [headerPosition, setHeaderPosition] = useState({
    top: topPositionHeader,
    width: 0,
  });
  const handleScroll = () => {
    if (ref.current) {
      if (
        ref.current.getBoundingClientRect()?.top <= thresholdSticky &&
        Math.abs(ref.current.getBoundingClientRect()?.top) + (thresholdSticky - 7) <= bodyRef.current.offsetHeight
      ) {
        // console.log(ref.current.getBoundingClientRect());
        setSticky(true);
      } else {
        setSticky(false);
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // console.log(bodyRef.current);
    // console.log(bodyRef.current.getBoundingClientRect());
    setHeaderPosition({
      ...headerPosition,
      width: bodyRef.current.getBoundingClientRect().right - bodyRef.current.getBoundingClientRect().left,
    });
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);
  return (
    <div className={`StickyHeader ${isSticky ? 'sticky' : ''}`} ref={ref}>
      <div className="sticky-inner" style={{ ...headerPosition }}>
        {children}
      </div>
    </div>
  );
};

export default StickyHeader;
