import React from 'react';
import style from './PageSplitter.module.scss';

const PageSplitter = ({ list, setCurrent }) => {
  const handleClick = ({ target }) => setCurrent((prev) => ({ options: prev.options, current: target.id }));
  let i = 0;
  return (
    <div className={style.PageSplitterContainer}>
      {list.options.map((item) => (
        <button
          key={item.id ?? ++i}
          onClick={handleClick}
          className={list.current === item && style.isActive}
          id={item}
        >
          {item.charAt(0).toUpperCase() + item.slice(1)}
        </button>
      ))}
    </div>
  );
};

export default PageSplitter;
