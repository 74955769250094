import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { registerCompany } from './AddNewCompanyModal.helper';
import './AddNewCompanyModal.scss';
import { generatePassword } from '@insightt/insightt-shared';
import Loader from '../loaders/Loader';
import { validatePassword } from '../../password';

function AddNewCompanyModal({ toggle, status }) {
  const [state, setState] = useState({ email: '', password: generatePassword() });
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const validation = validatePassword(state.password);
    if (validation.length > 0) {
      const validationErrors = validation.map((error) => error.message);
      toast.error('Invalid Password: ' + validationErrors.join(', '));
      return;
    }
    setLoading(true);
    registerCompany(state)
      .then((isDone) => {
        if (!isDone) toast.error('Error While creating new company try again!');
        else toast.success('Company Created Successfully!');
      })
      .finally(() => {
        setLoading(false);
        setState({ email: '', password: '' });
        toggle();
      });
  };

  return (
    <Modal className={'AddNewCompanyModal'} centered={true} toggle={toggle} isOpen={status}>
      <form onSubmit={onSubmit}>
        <ModalHeader>Add Company</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Company Email</Label>
            <Input
              placeholder="Enter Company Email Id"
              value={state.email}
              onChange={(e) => setState({ ...state, email: e.target.value })}
              required={true}
              type="email"
            />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              placeholder="Enter Company Password"
              value={state.password}
              onChange={(e) => setState({ ...state, password: e.target.value })}
              required={true}
              type="text"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Cancel</Button>
          <Button color="primary" type={'submit'}>
            {loading ? <Loader height={'40px'} width={'40px'} /> : 'Save'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default AddNewCompanyModal;
