import React, {useState} from "react";
import {Button, Input} from "reactstrap";
import {FaEye, FaEyeSlash, FaKey, FaRegEnvelope} from "react-icons/fa";
import {useHistory} from "react-router-dom";

import CLIFF_ICON from "../../shared/assets/cliff-green.png";
import "./LoginPage.scss";
import JesusQuote from "./JesusQuote";
import {loginRequest} from "./LoginPage.helper";
import Loader from "../../shared/components/loaders/Loader";

function LoginPage() {
    const history = useHistory();
    const [state, setState] = useState({email: "", password: ""});
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (key, value) => {
        setState({...state, [key]: value});
    };

    const onSubmit = async e => {
        e.preventDefault()
        setLoading(true);
        const success = await loginRequest(state.email, state.password)
        if (success) history.push("/company");
        else setLoading(false);
    };

    return (
        <div className={"LoginPage"}>
            <JesusQuote/>
            <div className="login-container">
                <div>
                    <form onSubmit={onSubmit}>
                        <p className="title">
                            <div className="icon-container">
                                <img src={CLIFF_ICON} alt={"CLIFF_ICON"} className={"cliff-icon"}/>
                            </div>
                            <div className="label">Login</div>
                        </p>
                        <div className="input-container">
                            <p className="form-title">
                                <FaRegEnvelope/> Email
                            </p>
                            <Input
                                type={"email"}
                                required={true}
                                placeholder={"Enter Email"}
                                value={state.email}
                                onChange={event => {
                                    handleChange("email", event.target.value);
                                }}
                            />
                        </div>
                        <div className="input-container">
                            <p className="form-title">
                                <FaKey/> Password
                            </p>
                            <Input
                                type={showPassword ? "text" : "password"}
                                required={true}
                                placeholder={"Enter Password"}
                                value={state.password}
                                onChange={event => {
                                    handleChange("password", event.target.value);
                                }}
                            />
                            <div className="password-toggle-container">
                                {showPassword ? (
                                    <FaEye onClick={() => setShowPassword(false)}/>
                                ) : (
                                    <FaEyeSlash onClick={() => setShowPassword(true)}/>
                                )}
                            </div>
                        </div>
                        <Button className={"login-btn"} type="submit">
                            {loading ? <Loader height={'40px'} width={'40px'}/> : "Login"}
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
